import React, { useState } from 'react';
import Navbar from '../components/NavBar';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://formspree.io/f/mvgpjnpy', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } else {
      alert('There was a problem with your submission.');
    }
  };

  return (
    <div>
      <Navbar />
          <div className="contact-container">
      <p className='ScholarlyAmbitionShadow'>Contact me!</p>
      <form onSubmit={handleSubmit} className="contact-form">
        <div>
          <label className="ScholarlyAmbitionShadowContactPage">Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label className="ScholarlyAmbitionShadowContactPage">Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label className="ScholarlyAmbitionShadowContactPage">Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Send</button>
      </form>

      <div className="contact-info">
        <p className='ScholarlyAmbitionShadow'>Connect with me!</p>
        <div className='contact-info-links'>
          <p className="ScholarlyAmbitionShadowContactPage">
          Email:  
          <a href="mailto:joliatsan@gmail.com" className="hover-underline-animation">joliatsan@gmail.com</a>
          </p>
          <p className="ScholarlyAmbitionShadowContactPage">
          Instagram:  
          <a href="https://instagram.com/jolia.ts" target="_blank" rel="noopener noreferrer" className="hover-underline-animation">@jolia.ts</a>
          </p>
          <p className="ScholarlyAmbitionShadowContactPage">
          LinkedIn:  
          <a href="https://www.linkedin.com/in/jolia-tsan/" target="_blank" rel="noopener noreferrer" className="hover-underline-animation">Jolia Tsan</a>
          </p>
        </div>
        
      </div>
    </div>
    </div>

  );
}

export default Contact;
