// Games.js
import React, { useState } from 'react';
import Navbar from '../components/NavBar'; // Your existing NavBar component
import pinktype2 from '../images/pinktype2.png';
import pinktypegif from '../images/typingprac.gif'
import aimtrainer from '../images/aimtrainer.png';
import aimtrainergif from '../images/aimtrainer.gif'

// List of games with title, thumbnail, gif, description, play link, and GitHub link
const gamesList = [
  {
    title: 'Pink Type',
    thumbnail: pinktype2,
    gif: pinktypegif,
    description: 'A fun and fast-paced typing game that improves your typing speed.',
    playLink: 'http://192.168.4.108:5500/src/pages/pinktype/index.html',
    githubLink: 'https://github.com/joliats/typing-game'
  },
  {
    title: 'Aim Training',
    thumbnail: aimtrainer,
    gif: aimtrainergif,
    description: 'A aim training game that is meant to help you practice for your first person shooter games with different difficulty levels to adjust',
    playLink: "./aimtrainer/index.html",
    githubLink: 'https://github.com/joliats/aim-trainer'
  }
];

const GameItem = ({ game }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="game-item" 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* If hovered, show the GIF, else show the thumbnail */}
      <img src={isHovered ? game.gif : game.thumbnail} alt={game.title} className="game-thumbnail"/>
      
      {isHovered && (
        <div className="game-details">
          <h3>{game.title}</h3>
          <p>{game.description}</p>
          <div className="game-links">
            <a href={game.playLink} className="play-btn">Play Now</a>
            <a href={game.githubLink} className="github-btn">GitHub</a>
          </div>
        </div>
      )}
    </div>
  );
};

function Games() {
  return (
    <div>
      <Navbar />
      <div className="games-grid">
        {gamesList.map((game, index) => (
          <GameItem key={index} game={game} />
        ))}
      </div>
    </div>
  );
}

export default Games;
