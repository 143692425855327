import React from 'react'
import Navbar from '../components/NavBar'
import CarVideosCard from '../pages/CarVideos';


function Projects () {
    return (
    <div> 
        <Navbar/>
        <CarVideosCard />
    </div>
    )
}

export default Projects