import React from 'react'
import KirbyHeadphones from '../images/KirbyHeadphones.gif'

function Kirby03() {
    return(
        <div>
          <img
            src={KirbyHeadphones}
            height={400}
            width={300} 
            alt="Kirby"
            />  
        </div>
    );
}    

export default Kirby03;
