import React, { useEffect, useState } from "react";
import "./aimtrainer.css";

const AimTrainer = () => {
  const [time, setTime] = useState(0);
  const [hits, setHits] = useState(0);
  const [missed, setMissed] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const startBtn = document.querySelector("#start");

    startBtn.addEventListener("click", (event) => {
      event.preventDefault();
      setPlaying(true);
      setHits(0);
      setMissed(0);
      setAccuracy(0);
      setTime(60); // You can adjust this starting time
      const id = setInterval(decreaseTime, 1000);
      setIntervalId(id);
    });

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const decreaseTime = () => {
    setTime((prevTime) => {
      if (prevTime === 0) {
        finishGame();
        return 0;
      } else {
        return prevTime - 1;
      }
    });
  };

  const finishGame = () => {
    clearInterval(intervalId);
    setPlaying(false);
  };

  const handleBoardClick = (event) => {
    if (event.target.classList.contains("circle")) {
      setHits((prevHits) => prevHits + 1);
      calculateAccuracy();
      event.target.remove();
      createRandomCircle();
    } else {
      setMissed((prevMissed) => prevMissed + 1);
      calculateAccuracy();
    }
  };

  const calculateAccuracy = () => {
    setAccuracy(((hits / (hits + missed)) * 100).toFixed(2));
  };

  const createRandomCircle = () => {
    const board = document.querySelector("#board");
    const circle = document.createElement("div");
    const size = Math.floor(Math.random() * 71) + 30;
    const { width, height } = board.getBoundingClientRect();
    const x = Math.floor(Math.random() * (width - size));
    const y = Math.floor(Math.random() * (height - size));
    circle.classList.add("circle");
    circle.style.width = `${size}px`;
    circle.style.height = `${size}px`;
    circle.style.position = "absolute";
    circle.style.left = `${x}px`;
    circle.style.top = `${y}px`;
    circle.style.backgroundColor = "var(--primary-color)";
    board.appendChild(circle);
  };

  return (
    <div>
      {!playing && (
        <div className="screen">
          <h1>Aim Trainer</h1>
          <button className="btn" id="start">
            Start Game
          </button>
        </div>
      )}
      {playing && (
        <div id="game-screen">
          <div className="stats">
            <div className="info">
              <p>
                Time: <span id="time">{time}</span>
              </p>
              <p>
                Hits: <span id="hits">{hits}</span>
              </p>
              <p>
                Accuracy: <span id="accuracy">{accuracy}%</span>
              </p>
            </div>
          </div>
          <div className="board" id="board" onClick={handleBoardClick}></div>
        </div>
      )}
    </div>
  );
};

export default AimTrainer;
