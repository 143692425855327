import React from 'react'
import { Link } from 'react-router-dom';
import AboutMe from '../components/AboutMe';
import ContactBunny from '../components/Contact';
import ProjectsKirby from '../components/Projects';

function Home () {
    return (
    <div id="header"> 

        <Link to = '/aboutme'>
            <div className='Heart01'> 
                <h1 className='ScholarlyAmbitionShadow'>about me</h1>
            </div>
            <div className='Heart02'>        
                <AboutMe/>
            </div>     
        </Link>

        <Link to = '/contact'>
            <div className='Bunny01'> 
                <h1 className='ScholarlyAmbitionShadow'>contact</h1>
            </div>
            <div className='Bunny02'>        
                <ContactBunny/>
            </div>     
        </Link>

        <Link to = '/mywork'>
            <div className='Kirby01'> 
                <h1 className='ScholarlyAmbitionShadow'>my work</h1>
            </div>
            <div className='Kirby02'>        
                <ProjectsKirby/>
            </div>     
        </Link>

    </div>
    )
}
export default Home 