import React from 'react'
import Navbar from '../components/NavBar'
import PhotoGrid from '../components/photogrid';
import jeanskirt from '../images/JT_JeanSkirt.jpg';

const photos = [jeanskirt,];

function Clothing() {
    return (
        <div>
            <Navbar />
            <PhotoGrid photos={photos}/>
        </div>
    );
}

export default Clothing;
