import React from "react";
import icons from "../images/icons.png";

function Skillsicons() {
    return(
        <div>
          <img
            src={icons}
            alt="icons"
            />  
        </div>
    );
}    

export default Skillsicons;
