import React, { useState } from 'react';

const PhotoGrid = ({ photos }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const openModal = (photo) => {
    setSelectedPhoto(photo);
  };

  const closeModal = () => {
    setSelectedPhoto(null);
  };

  return (
    <div>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Photo ${index}`}
            className="photo-item"
            onClick={() => openModal(photo)}
          />
        ))}
      </div>

      {selectedPhoto && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedPhoto} alt="Enlarged view" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGrid;
