import React from "react";
import heart01 from "../images/heart01.gif";

function AboutMe() {
    return(
        <div className='Heart01'>
          <img
            src={heart01}
            height={200}
            width={175} 
            alt="about me"
            />
        </div>
    );
}    

export default AboutMe;
