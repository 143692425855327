import React from "react";
import bunny01 from "../images/bunny01.gif";

function ContactBunny() {
    return(
        <div className='Bunny01'>
          <img
            src={bunny01}
            height={200}
            width={175} 
            alt="contact"
            />
        </div>
    );
}    

export default ContactBunny;
