import React from "react";

function Box(props) {
    return (
<a href={props.link}>
<h1 className="box">{props.name}</h1>

</a>
    )
    };  

export default Box;
