import React from "react";
import Star from "../images/star.gif";

function Star01() {
    return(
        <div>
          <img
            src={Star}
            height={100}
            width={100} 
            alt="Star"
            />  
        </div>
    );
}    

export default Star01;
