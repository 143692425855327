import React from 'react'
import Kirby from '../components/Kirby';
import { Link } from 'react-router-dom';

function Welcome () {
    return (
    <div>
        <div className='KirbyStart'>
            <h1 className='ScholarlyAmbition'>Hii Welcome to Jolia's Place :)</h1>
            <div>
                <Link to = "home">
                    <Kirby/>
                </Link>
            </div>
        </div>
        
    </div>
);
}
export default Welcome