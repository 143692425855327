import React from 'react'
import MemoPad from '../components/MemoPadBackground'
// import memopad01 from "../images/memopad01.jpg";
// import Me from '../components/JoliaPhoto'
import Jolia from "../images/jolia.png";
import Skillsicons from "../components/icons";
import Navbar from '../components/NavBar';



function About () {
    return (
    <div>
        <Navbar />

      <div className='row'>
        <div className='memopad'>
        <p className='memopadtext'>Hello! My name Jolia Tsan and I’m a seventeen year old highschool junior currently attending Boston Latin school. I work at Artists for Humanity as a web and game developer. I previously animated for Artists for Humanity using Adobe suite. My work at AFH ranges from creating interactive displays for events to doing client work whether it be animation or web applications. I aspire to do creative work in the future that will allow me to immerse myself with technology.</p>
        {/* <MemoPad/> */}
        </div>

        <div>
            <div>
                <Skillsicons></Skillsicons>
                <img 
                src={Jolia}
                height={450}
                // width={175} 
                alt="photo of me"
                />       
            </div>

        
        </div>
      
    </div>
    
    </div>    
    
        
    )
}

export default About