import React from 'react';
import Navbar from '../components/NavBar';
import PhotoGrid from '../components/photogrid';
import purplelady from '../images/JT_purplelady.jpeg';
import heartgirl from '../images/JT_heartgirl.jpeg';
import washitapedress from '../images/JT_washitapedress.jfif';
import castle from '../images/JT_CastleEnvironment.jpg';
import apple from '../images/JT_apple.jfif';
import car from '../images/JT_car.jpg';
import car1 from '../images/JT_car1.jpg';

const photos = [car, car1, purplelady, heartgirl, washitapedress, castle, apple];

function Art() {
    return (
        <div>
            <Navbar />
            <PhotoGrid photos={photos}/>
        </div>
    );
}

export default Art;
