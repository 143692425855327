import React from "react";
import kirby01 from "../images/kirby01.gif";

function Kirby() {
    return(
        <div>
          <img
            src={kirby01}
            height={300}
            width={300} 
            alt="Kirby"
            />  
        </div>
    );
}    

export default Kirby;
