import React from "react";
import kirby02 from "../images/kirby02.gif";

function Projects() {
    return(
        <div className='Kirby01'>
          <img
            src={kirby02}
            height={250}
            width={225} 
            alt="Projects"
            />
        </div>
    );
}    

export default Projects;