// import React from "react";
// import memopad01 from "../images/memopad01.jpg";

// function MemoPad() {
//     return(
//           <img
//             src={memopad01}
//             alt=""
//             />
//     );
// }    

// export default MemoPad;
// ImageScaler.js
import memopad01 from "../images/memopad01.jpg";
import '../index.css';
// import '../pages/aimtrainer.css';
function MemoPad() {
  return (
    
      <img
        src={memopad01}
        alt="background"
        width={650}
        height={650}
      />
  );
};
export default MemoPad;
